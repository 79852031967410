import request from '@/utils/request.js'

// 大屏列表
export function getDataScreenManagementListApi(data) {
  return request({
    url: '/doftec/screenModule/list',
    method: 'post',
    data: data
  })
}

// 大屏列表 -增加
export function getDataScreenManagementCreateApi(data) {
  return request({
    url: '/doftec/screenModule/create',
    method: 'post',
    data: data
  })
}

// 大屏列表 - 修改
export function getDataScreenManagementUpdateApi(data) {
  return request({
    url: '/doftec/screenModule/update',
    method: 'post',
    data: data
  })
}

// 大屏列表 - 删除
export function getDataScreenManagementDeleteApi(data) {
  return request({
    url: '/doftec/screenModule/action',
    method: 'post',
    data: data
  })
}

// 大屏列表 - 详情
export function getDataScreenManagementInfoApi(params) {
  return request({
    url: '/doftec/screenModule/info',
    method: 'get',
    params: params
  })
}