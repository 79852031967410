<template>
    <div class="app-content">
        <el-form
            ref="ruleForm"
            :model="form"
            :rules="rules"
            label-width="80px"
            label-position="top"
        >
            <el-row type="flex" justify="center">
                <el-col :sm="20" :lg="16">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <b>数据大屏信息</b>
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="10">
                                <el-form-item label="名称" prop="moduleName">
                                    <el-input
                                        v-model="form.moduleName"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>

                            <el-col :span="24">
                                <el-form-item label="数据" prop="moduleContent">
                                    <!-- <el-input
                                        v-model="form.moduleContent"
                                        type="textarea"
                                        :rows="20"
                                        placeholder="请输入"
                                    /> -->
                                    <b-code-editor v-model="form.moduleContent" height="auto"  theme="dracula" :indent-unit="2" ref="editor"></b-code-editor>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-button type="primary" size="small" @click="$refs['editor'].formatCode()">手动触发格式化</el-button>
                        <el-button type="primary" size="small" @click="dialogVisible=true">放大编辑器</el-button>
                    </el-card>
                    <el-card
                        class="box-card bottom-card"
                        style="margin-top: 10px"
                    >
                        <el-row type="flex" justify="end">
                            <el-button type="primary" @click="submitForm('ruleForm')"
                                >提 交</el-button
                            >
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
            <el-dialog
            title="模板内容"
            :visible.sync="dialogVisible"
            width="100%"
            fullscreen
            :before-close="()=>dialogVisible=false" class="dialog">
            <div>
                <b-code-editor v-model="form.moduleContent" height="auto"  theme="dracula" :indent-unit="2" ref="editor"></b-code-editor>
            </div>
        </el-dialog>
        </el-form>
    </div>
</template>

<script>
import {
    getDataScreenManagementCreateApi,
    getDataScreenManagementUpdateApi,
    getDataScreenManagementInfoApi,
} from '@/api/dataScreenManagement'
export default {
    data() {
        return {
            dialogVisible:false,
            form: {
                moduleContent:'[]'
            },
            rules: {
                moduleName: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                moduleContent: [
                    {
                        required: true,
                        message: '请输入数据',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    created() {
        if (this.$route.query.id) {
            this.getDataScreenManagementInfoFun()
        }
    },
    methods: {
        // 获取文章详情
        async getDataScreenManagementInfoFun() {
            var params = {
                id: this.$route.query.id,
            }
            var res = await getDataScreenManagementInfoApi(params)
            this.form = res
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.$route.query.id) {
                        this.getDataScreenManagementUpdateFun()
                    } else {
                        this.getDataScreenManagementCreateFun()
                    }
                } else {
                    this.$message({
                        type: 'error',
                        message: '请检查是否有提示选项'
                    })
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 新增文章
        async getDataScreenManagementCreateFun() {
            var res = await getDataScreenManagementCreateApi(this.form)
            if (res.code === 200) {
                this.$message({
                    message: '新增成功',
                    type: 'success',
                })
                this.$router.go(-1)
            } else {
                this.$message({
                    message: '新增失败',
                    type: 'error',
                })
            }
        },
        // 更新文章
        async getDataScreenManagementUpdateFun() {
            delete this.form.createTime
            var res = await getDataScreenManagementUpdateApi(this.form)
            if (res.code === 200) {
                this.$message({
                    message: '更新成功',
                    type: 'success',
                })
                this.$router.go(-1)
            } else {
                this.$message({
                    message: '更新失败',
                    type: 'error',
                })
            }
        },
    },
}
</script>

<style scoped>
.app-content {
    width: 1200px;
    margin: 20px auto 20px auto;
    /* margin-top: 20px; */
}
::v-deep .el-form-item {
    width: 100%;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
::v-deep .CodeMirror{
    height: 500px;
}
::v-deep .dialog .CodeMirror{
    height: 87vh;
}
</style>
